import { FirebaseApp, getApps, initializeApp } from 'firebase/app'
import {
  getAuth,
  browserLocalPersistence,
  GoogleAuthProvider,
  inMemoryPersistence,
} from 'firebase/auth'

import config from 'constants/config'

let _app: FirebaseApp | undefined
if (getApps().length < 1) {
  _app = initializeApp({
    apiKey: config.FIREBASE_API_KEY,
    appId: config.FIREBASE_APP_ID,
    projectId: config.GOOGLE_PROJECT_ID,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    messagingSenderId: config.REACT_APP_FCM_SENDER_ID,
  })
}
const app = _app

const auth = getAuth()

auth.setPersistence(
  process.env.NODE_ENV === 'test'
    ? inMemoryPersistence
    : browserLocalPersistence,
)

const googleAuthProvider = new GoogleAuthProvider()

export { app, auth, googleAuthProvider }
