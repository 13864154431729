import { Row, Col } from 'antd'
import { Flex, Card } from '@weareberyl/design-system'

import { ZoneType } from '__generated__/globalTypes'
import HeadTitle from 'components/HeadTitle'
import { ZoneHeader } from '../Summary'

type ZoneProps = {
  id: string
  schemeId: string
}

const Zone = ({ id, schemeId }: ZoneProps) => {
  console.log('ZoneHistory: schemeId: ', schemeId)
  return (
    <Card id="zone-summary-card" mb={3} p={5}>
      <Flex
        justifyContent="space-between"
        alignItems="right"
        width="100%"
        mb={2}
      >
        <HeadTitle pageTitle={`Bay ${id} History`} />
        <Row gutter={16} className="fill-height">
          <Col xs={24}>
            <ZoneHeader zoneName={'Example Zone'} zoneType={ZoneType.normal} />
          </Col>
        </Row>
      </Flex>
    </Card>
  )
}

export default Zone
