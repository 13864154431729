import { useState, useEffect } from 'react'
import { gql } from '@apollo/client'
import { message, Select, Checkbox } from 'antd'

import {
  Flex,
  Box,
  Card,
  Heading,
  Button,
  Text,
  Icon,
} from '@weareberyl/design-system'

import { SERVICE_AREA_POLICY_service_area } from './__generated__/SERVICE_AREA_POLICY'
import {
  UPDATE_SERVICE_AREA_POLICYVariables,
  UPDATE_SERVICE_AREA_POLICY,
} from './__generated__/UPDATE_SERVICE_AREA_POLICY'
import {
  ServiceAreaType,
  ServiceAreaPolicyInput,
  VehicleType,
} from '__generated__/globalTypes'

import { useQuery } from 'components/Query'
import { InputWrapper } from './Input'
import { useMutation } from '@apollo/client'
import BooleanIcon from '../BooleanIcon'
import { ServiceAreasTable } from '../List'
import { TimePicker } from 'antd'

// TODO: When we update to antd 4.x
// https://ant.design/docs/react/replace-moment
import moment from 'moment'
import { VehicleIcon } from 'utils'
import HeadTitle from 'components/HeadTitle'

const SERVICE_AREA_POLICY_QUERY = gql`
  query SERVICE_AREA_POLICY($id: ID!) {
    service_area(service_area_id: $id) {
      __typename
      id
      name
      speed_limit
      service_area_type
      is_parking_allowed
      effective_from
      effective_to
      applies_to
      scheme {
        id
      }
    }
  }
`

const UPDATE_SERVICE_AREA_POLICY_MUTATION = gql`
  mutation UPDATE_SERVICE_AREA_POLICY(
    $service_area_id: ID!
    $service_area_policy: ServiceAreaPolicyInput!
  ) {
    update_service_area_policy(
      service_area_id: $service_area_id
      service_area_policy: $service_area_policy
    ) {
      __typename
      id
      speed_limit
      service_area_type
      is_parking_allowed
      effective_from
      effective_to
      applies_to
    }
  }
`

const serviceAreaTypeNames: { [key in ServiceAreaType]: string } = {
  [ServiceAreaType.normal]: 'Service area',
  [ServiceAreaType.go_slow]: 'Go slow zone',
  [ServiceAreaType.no_go]: 'No go zone',
  [ServiceAreaType.segment]: 'Segment',
}

interface Props {
  id: string
}
const Edit = ({ id }: Props): JSX.Element => {
  const [editing, setEditing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [effectiveFrom, setEffectiveFrom] = useState<string | null>()
  const [effectiveTo, setEffectiveTo] = useState<string | null>()
  const [speedLimit, setSpeedLimit] = useState<number | null>()
  const [isParkingAllowed, setIsParkingAllowed] = useState<boolean | null>()
  const [serviceAreaType, setServiceAreaType] =
    useState<ServiceAreaType | null>()
  const [appliesTo, setAppliesTo] = useState<VehicleType[]>([])

  const { Component: ServiceAreaPolicyQuery, queryProps } = useQuery({
    query: SERVICE_AREA_POLICY_QUERY,
    pollInterval: 0,
    waitFor: 'data.service_area',
    variables: { id },
  })

  useEffect(() => {
    // Update state with values from query or post-mutation cache update
    if (queryProps.data?.service_area) {
      reset(queryProps.data.service_area)
    }
  }, [queryProps.data])

  const [updateServiceAreaPolicy] = useMutation<
    UPDATE_SERVICE_AREA_POLICY,
    UPDATE_SERVICE_AREA_POLICYVariables
  >(UPDATE_SERVICE_AREA_POLICY_MUTATION)

  function reset(service_area: SERVICE_AREA_POLICY_service_area) {
    setEffectiveFrom(service_area.effective_from)
    setEffectiveTo(service_area.effective_to)
    setSpeedLimit(service_area.speed_limit)
    setIsParkingAllowed(service_area.is_parking_allowed)
    setServiceAreaType(service_area.service_area_type)
    setAppliesTo(service_area.applies_to)
  }

  function toggleEditing() {
    setEditing(value => !value)
  }

  function cancel() {
    setEditing(false)
    reset(queryProps.data.service_area)
  }

  async function save() {
    setLoading(true)
    // These are checked for type safety, byt we might be able to tie up the type a bit better,
    // as these fields will be fetched from the query on the first render.
    try {
      if (speedLimit === null || speedLimit === undefined) {
        message.error('Please set a speed limit')
        return
      }
      if (isParkingAllowed === null || isParkingAllowed === undefined) {
        message.error('Is parking allowed?')
        return
      }
      const service_area_policy: ServiceAreaPolicyInput = {
        speed_limit: speedLimit,
        is_parking_allowed: isParkingAllowed,
        effective_from: effectiveFrom,
        effective_to: effectiveTo,
        service_area_type: serviceAreaType,
        applies_to: appliesTo,
      }
      await updateServiceAreaPolicy({
        variables: {
          service_area_id: id,
          service_area_policy,
        },
      })

      message.success(`Succesfully saved service area: ${id}`)
    } catch (err) {
      // Reset to the most recent data from GraphQL
      if (queryProps.data) {
        reset(queryProps.data.service_area)
      }
      message.error(`Something went wrong trying to save: ${err.message}`)
    } finally {
      setLoading(false)
      setEditing(false)
    }
  }

  return (
    <>
      <Card id="service-area-summary-card" mb={3} p={5}>
        <ServiceAreaPolicyQuery>
          {({
            data: {
              service_area: { name, service_area_type },
            },
          }) => (
            <>
              <HeadTitle pageTitle={name} />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                mb={2}
              >
                <Box flex="1" mb={3}>
                  <Heading variant="callout">{name}</Heading>
                  <Heading variant="h1">
                    <Icon type="serviceArea" width={26} height={20} mr={2} />

                    {serviceAreaTypeNames[service_area_type]}
                  </Heading>
                </Box>
              </Flex>
            </>
          )}
        </ServiceAreaPolicyQuery>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb={2}
        >
          <Card variant="gray">
            <InputWrapper label="Effective from">
              <TimePicker
                value={
                  effectiveFrom ? moment(effectiveFrom, 'HH:mm') : undefined
                }
                minuteStep={15}
                format={'HH:mm'}
                disabled={!editing}
                onChange={(_, time: string) => {
                  if (time) {
                    setEffectiveFrom(time)
                  } else {
                    setEffectiveFrom(null)
                  }
                }}
              />
            </InputWrapper>
            <InputWrapper label="Effective to">
              <TimePicker
                value={effectiveTo ? moment(effectiveTo, 'HH:mm') : undefined}
                minuteStep={15}
                format={'HH:mm'}
                disabled={!editing}
                onChange={(_, time: string) => {
                  if (time) {
                    setEffectiveTo(time)
                  } else {
                    setEffectiveTo(null)
                  }
                }}
              />
            </InputWrapper>
            <InputWrapper label="Speed limit">
              {editing ? (
                <Select
                  value={speedLimit}
                  onSelect={(value: number) => setSpeedLimit(value)}
                >
                  <Select.Option value={24}>24 km/h</Select.Option>
                  <Select.Option value={20}>20 km/h</Select.Option>
                  <Select.Option value={13}>13 km/h</Select.Option>
                  <Select.Option value={8}>8 km/h</Select.Option>
                  <Select.Option value={5}>5 km/h</Select.Option>
                  <Select.Option value={0}>0 km/h</Select.Option>
                </Select>
              ) : (
                <Text>{speedLimit} km/h</Text>
              )}
            </InputWrapper>
            <InputWrapper label="Parking allowed?">
              {editing ? (
                <Select
                  value={isParkingAllowed ? 1 : 0}
                  onSelect={(value: number) => setIsParkingAllowed(!!value)}
                >
                  <Select.Option value={1}>YES</Select.Option>
                  <Select.Option value={0}>NO</Select.Option>
                </Select>
              ) : (
                <Flex>
                  <Box flex={0}>
                    <BooleanIcon value={isParkingAllowed ?? false} />
                  </Box>
                </Flex>
              )}
            </InputWrapper>
            <InputWrapper label="Zone type">
              {editing ? (
                <Select
                  value={serviceAreaType}
                  placeholder="Select a type"
                  onSelect={setServiceAreaType}
                >
                  {Object.entries(serviceAreaTypeNames).map(([value, name]) => (
                    <Select.Option key={value} value={value}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Text>
                  {serviceAreaType && serviceAreaTypeNames[serviceAreaType]}
                </Text>
              )}
            </InputWrapper>
            <InputWrapper label="Affected vehicles">
              {editing ? (
                <Checkbox.Group
                  value={appliesTo}
                  name="appliesTo"
                  onChange={checkedValue =>
                    setAppliesTo(checkedValue as VehicleType[])
                  }
                >
                  {[
                    VehicleType.bike,
                    VehicleType.scooter,
                    VehicleType.cargo_bike,
                  ].map(vehicle => (
                    <Checkbox value={vehicle} key={vehicle}>
                      <div style={{ display: 'inline-block' }}>
                        <VehicleIcon vehicle={vehicle} size={21} />
                      </div>
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              ) : (
                <Flex>
                  {appliesTo.map(vehicle => (
                    <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
                  ))}
                </Flex>
              )}
            </InputWrapper>
            {editing ? (
              <Flex>
                <Button
                  mt={3}
                  variant="outline"
                  title="Cancel"
                  onPress={cancel}
                />
                <Button
                  variant="primary"
                  mt={3}
                  ml={3}
                  title="Save"
                  onPress={save}
                  loading={loading}
                />
              </Flex>
            ) : (
              <Flex>
                <Button mt={3} title="Edit" onPress={toggleEditing} />
              </Flex>
            )}
          </Card>
        </Flex>
      </Card>
      <Card p={5}>
        <ServiceAreasTable />
      </Card>
    </>
  )
}

export default Edit
