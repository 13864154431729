import React from 'react'
import { withRouter } from 'react-router'
import { gql, ApolloQueryResult } from '@apollo/client'
import { Flex, Card, Heading, Button } from '@weareberyl/design-system'
import Query from 'components/Query'
import { withScheme } from 'components/Scheme'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import * as routes from 'constants/routes'
import Tabs from 'components/shared/Tabs'
import AssetLink from 'components/Scheme/AssetLink'
import SuspendedStatus from 'components/Zone/SuspendedStatus'
import { EmptyComponent } from 'components/Query/utils'

import {
  SERVICE_AREAS_LIST,
  SERVICE_AREAS_LIST_table_nodes,
} from 'components/ServiceArea/List/__generated__/SERVICE_AREAS_LIST'
import BooleanIcon from '../BooleanIcon'
import { VehicleIcon } from 'utils'
import HeadTitle from 'components/HeadTitle'

const { Tab } = Tabs

const SERVICE_AREAS_LIST_QUERY = gql`
  query SERVICE_AREAS_LIST($scheme_id: ID, $paginate: Paginate) {
    table: all_service_areas(scheme_id: $scheme_id, paginate: $paginate) {
      nodes {
        __typename
        id
        name
        speed_limit
        is_parking_allowed
        effective_from
        effective_to
        applies_to
        service_area_type
        scheme {
          id
        }
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
`

interface IColumn {
  title: string
  dataIndex?: string
  render?: (service_area: SERVICE_AREAS_LIST_table_nodes) => React.ReactNode
}

const columns: IColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    render: ({ name, id, scheme }) => (
      <AssetLink id={id} schemeId={scheme.id} basePath={routes.SERVICE_AREAS}>
        {name}
      </AssetLink>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'service_area_type',
  },
  {
    title: 'Speed restrictions km/h',
    dataIndex: 'speed_limit',
  },
  {
    title: 'Parking allowed?',
    render: ({ is_parking_allowed }) => (
      <BooleanIcon value={is_parking_allowed} />
    ),
  },
  {
    title: 'Operational time',
    render: ({ effective_from, effective_to }) =>
      effective_from && effective_to && `${effective_from} - ${effective_to}`,
  },
  {
    title: 'Applies to',
    render: ({ applies_to }) => (
      <Flex>
        {applies_to.map(vehicle => (
          <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
        ))}
      </Flex>
    ),
  },
]

const id = 'service-areas-table'

const ServiceAreas = ({ scheme, history }) => {
  return (
    <Card p={5}>
      <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
        <Heading variant="callout">Service Areas</Heading>
        <Button
          title={'Sync'}
          onPress={async () => {
            history.push(`/scheme/${scheme.id}${routes.SERVICE_AREAS_SYNC}`)
          }}
          variant="primary"
          width="260px"
        />
      </Flex>
      <ServiceAreasTable />
    </Card>
  )
}

export const ServiceAreasTable = withScheme(({ scheme }): JSX.Element => {
  const [{ current, pageSize }, setQueryParams] = useQueryParams(id)
  return (
    <>
      <HeadTitle pageTitle="Service Areas" />
      <Query
        waitFor="data.table"
        pollInterval={0}
        variables={{
          scheme_id: scheme.id,
          paginate: {
            page: current,
            per_page: pageSize,
          },
        }}
        query={SERVICE_AREAS_LIST_QUERY}
      >
        {(props: ApolloQueryResult<SERVICE_AREAS_LIST>) => {
          const isEmptyResult = (props.data.table?.nodes || []).length === 0

          return (
            <Card variant="gray" p={3} mt={4}>
              <Tabs>
                <Tab isSelected>
                  <SuspendedStatus isSuspended={false} asHeader />
                  <Heading variant="callout">
                    {props.data?.table.pagination.total || 0}
                  </Heading>
                </Tab>
              </Tabs>
              <Card
                variant="borderless"
                borderTopLeftRadius={0}
                borderTopRightRadius={0}
              >
                {isEmptyResult ? (
                  <EmptyComponent />
                ) : (
                  <Table
                    id={id}
                    columns={columns}
                    onChange={({ current, pageSize }) =>
                      setQueryParams({ current, pageSize })
                    }
                    {...props}
                  />
                )}
              </Card>
            </Card>
          )
        }}
      </Query>
    </>
  )
})

export default withRouter(withScheme(ServiceAreas))
