import { Box, Card, Flex, Heading, colors } from '@weareberyl/design-system'
import { message } from 'antd'
import React from 'react'

import Loading from 'components/Loading'
import CardSection from 'components/shared/CardSection'
import Dropdown from 'components/shared/Dropdown'
import TabTitle from 'components/shared/TabTitle'
import { useZoneSummaryQuery, ZoneType } from 'gql/generated/graphql'
import {
  VehicleIcon,
  capitalize,
  getPositionLinkFromCoordinates,
  isDockedScheme,
  isPromotionalZoneScheme,
} from 'utils'
import { getDerivedZone } from 'utils/derived'

import { SetPromotional } from './Actions/SetPromotional'
import { SuspendZone } from './Actions/SuspendZone'
// import { ViewHistory } from './Actions/ViewHistory'
import ZoneNotes from './ZoneNotes'
import ZoneTypeStatus from './ZoneTypeStatus'
import useIsAnyLoading from './useIsAnyLoading'

const getHiringOrParkingString = (status: string | undefined) =>
  status ? capitalize(status.split('_')[0]) : undefined

export const ZoneHeader = ({
  zoneType,
  zoneName,
  children,
  ...props
}: {
  zoneType: ZoneType
  zoneName: string
  children?: React.ReactNode
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="baseline"
      width="100%"
      gridGap={1}
      {...props}
    >
      <Box flex="1">
        <Flex flexDirection="row" flexWrap="nowrap">
          <ZoneTypeStatus zoneType={zoneType} />
          <Heading variant="h1" pl={2}>
            {zoneName}
          </Heading>
        </Flex>
      </Box>
      {children}
    </Flex>
  )
}

const Summary = ({ id }) => {
  const [isAnyLoading, setAnyLoading] = useIsAnyLoading()
  const { data, error, loading } = useZoneSummaryQuery({ variables: { id } })

  if (loading) {
    return <Loading />
  }

  if (error) {
    message.error(error.message)
    return null
  }

  const zone = getDerivedZone(data?.zone, data?.zone?.scheme?.id)
  const schemeId = zone?.scheme?.id ?? null
  const isSuspended = zone?.is_suspended ?? false
  const zoneType = zone?.zone_type ?? ZoneType.normal
  const showDockCount = Boolean(schemeId && isDockedScheme(schemeId))
  const showPromotional = Boolean(schemeId && isPromotionalZoneScheme(schemeId))
  // Never mark a bay as promotional in schemes that have showPromotional = false
  const isPromotional = Boolean(zone?.is_promotional && showPromotional)

  const centreAndStatusList = [
    {
      key: 'Centre',
      value: getPositionLinkFromCoordinates(zone?.centre?.coordinates),
    },
    {
      key: 'Hiring status',
      value: getHiringOrParkingString(zone?.hiring_capacity_status),
    },
    {
      key: 'Parking status',
      value: getHiringOrParkingString(zone?.parking_capacity_status),
    },
  ]

  if (showPromotional) {
    centreAndStatusList.push({
      key: 'Promotional status',
      value: zone?.is_promotional ? 'Promotional' : 'Not Promotional',
    })
  }

  return (
    <Card id="zone-summary-card" mb={3} p={5}>
      <Flex flexDirection="column" gridGap={2}>
        <ZoneHeader zoneType={zoneType} zoneName={zone?.name ?? 'Unknown'}>
          <Box bg={colors.white}>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <SuspendZone
                        id={id}
                        isSuspended={isSuspended}
                        setAnyLoading={setAnyLoading}
                      />
                    ),
                    key: 'suspend-zone',
                  },
                  {
                    label: showPromotional ? (
                      <SetPromotional
                        id={id}
                        isPromotional={isPromotional}
                        setAnyLoading={setAnyLoading}
                      />
                    ) : null,
                    key: 'set-promotional',
                  },
                  // TODO: will be enabled in a followup PR
                  // {
                  //   label: schemeId ? (
                  //     <ViewHistory id={id} schemeId={schemeId} />
                  //   ) : null,
                  //   key: 'view-history',
                  // },
                ].filter(item => item.label !== null),
              }}
            />
          </Box>
        </ZoneHeader>

        <Flex justifyContent="space-between" alignItems="center">
          <Flex gridGap={16}>
            <TabTitle
              tabText={isSuspended ? 'Suspended' : 'Enabled'}
              icon={isSuspended ? 'cross' : 'tick'}
            />

            {isPromotional && (
              <TabTitle tabText={'Promotional'} icon={'tick'} />
            )}
          </Flex>

          {isAnyLoading && (
            <Box>
              <Loading />
            </Box>
          )}
        </Flex>

        <Box mt={1}>
          <ZoneNotes zone={zone} />
        </Box>

        <Flex justifyContent="space-between" width="100%">
          <Box flex="1" mr={5}>
            <Flex>
              <CardSection
                title="Total vehicles"
                titleValue={zone?.all_commissioned_modules_count.toString()}
                items={[]}
              />
            </Flex>
            <Flex>
              <CardSection
                title="Capacity"
                titleValue={zone?.capacity.toString()}
                titleStyle={{ mt: 1 }}
                items={[]}
              />
            </Flex>
            {showDockCount && (
              <Flex>
                <CardSection
                  title="Docked vehicles"
                  titleValue={zone?.docked_vehicles_count.toString()}
                  titleStyle={{ mt: 1 }}
                  items={[]}
                />
              </Flex>
            )}
            <Flex>
              <CardSection
                title="Permitted vehicles"
                titleValue={
                  <Flex>
                    {zone?.permitted_vehicles.map(vehicle => (
                      <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
                    ))}
                  </Flex>
                }
                titleStyle={{ mt: 1 }}
                items={[]}
              />
            </Flex>
          </Box>
          <Box flex="1">
            <Flex>
              <CardSection
                title="Hireable"
                titleValue={zone?.hireable_modules_count.toString()}
                items={[
                  { key: 'Bikes', value: zone?.hireable_bikes },
                  { key: 'E-Bikes', value: zone?.hireable_ebikes },
                  {
                    key: 'E-Scooters',
                    value: zone?.hireable_scooters,
                  },
                ]}
              />
            </Flex>
          </Box>
        </Flex>

        <Flex justifyContent="space-between" alignItems="flex-end" width="100%">
          <Box flex="1" mr={5}>
            <Flex>
              <CardSection items={centreAndStatusList} />
            </Flex>
          </Box>
          <Box flex="1">
            <Flex mt={1}>
              <CardSection
                title="Not hireable"
                titleValue={(
                  (zone?.all_commissioned_modules_count ?? 0) -
                  (zone?.hireable_modules_count ?? 0)
                ).toString()}
                items={[
                  {
                    key: 'Bikes',
                    value: (zone?.bikes ?? 0) - (zone?.hireable_bikes ?? 0),
                  },
                  {
                    key: 'E-Bikes',
                    value: (zone?.ebikes ?? 0) - (zone?.hireable_ebikes ?? 0),
                  },
                  {
                    key: 'E-Scooters',
                    value:
                      (zone?.scooters ?? 0) - (zone?.hireable_scooters ?? 0),
                  },
                ]}
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Card>
  )
}

export default Summary
